/* menu grey #242d3e*/
/* dashboard #181f2a*/

/* corpblue #001864 */
/* corpgold #FFC20E *
/* corplightblue #20C4F4 */

body {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  margin: 0px !important;
  height: 20% !important;
  background-color: #f5f6f8 !important;
}

body.dark {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  margin: 0px !important;
  height: 20% !important;
  background-color: #181f2a !important;
}

#root {
  font-family: "Montserrat", sans-serif !important;
}

.appcards {
  color: #001864 !important;
  background-color: #ffffff !important;
  margin: 1vh;
}

.appcardsdark {
  color: #ffffff !important;
  background-color: #242d3e !important;
  margin: 1vh;
}

.appcardstitle {
  color: #242d3e !important;
}

.appcardstitledark {
  color: #ffffff !important;
}

.appcardscontent {
  color: #242d3e !important;
}

.appcardscontentdark {
  color: #ffffff !important;
}

.searchfield {
  color: #242d3e !important;
  border-color: #242d3e !important;
}

.searchfielddark {
  color: #ffffff !important;
  border-color: #ffffff !important;
}
