/* menu grey #242d3e*/
/* dashboard #181f2a*/

/* corpblue #001864 */
/* corpgold #FFC20E *
/* corplightblue #20C4F4 */

.appbar {
  background-color: #001864 !important;
  color: #ffc20e !important;
}

.appbardark {
  background-color: #242d3e !important;
  color: #ffffff !important;
}

.appbarbuttons {
  color: #ffc20e !important;
}

.appbarbuttonsdark {
  color: #ffffff !important;
}
