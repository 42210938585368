/* menu grey #242d3e*/
/* dashboard #181f2a*/

/* corpblue #001864 */
/* corpgold #FFC20E *
/* corplightblue #20C4F4 */

.footer {
  color: #001864;
  margin-right: 0;
  background-image: none;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  bottom: 0;
  left: auto;
  right: auto;
  background-color: transparent;
  width: 100%;
  text-align: center;
}

.footerdark {
  color: #ffffff;
  margin-right: 0;
  background-image: none;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  bottom: 0;
  left: auto;
  right: auto;
  background-color: transparent;
  width: 100%;
  text-align: center;
}

.alerticonactive {
  color: #ffc20e;
}

.alerticonactivedark {
  color: #ffc20e;
}

.alerticon {
  color: #969696;
}

.alerticondark {
  color: #969696;
}
