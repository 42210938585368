/* menu grey #242d3e*/
/* dashboard #181f2a*/

/* corpblue #001864 */
/* corpgold #FFC20E *
/* corplightblue #20C4F4 */

.companyname {
  padding-top: 2vh;
  font-size: 1.5em;
  font-weight: 800;
  color: rgb(0, 0, 0);
  font-family: "Montserrat" !important;
  width: 100% !important;
  align-items: center !important;
  display: inline-flex !important;
  text-align: center !important;
  justify-content: center !important;
}


