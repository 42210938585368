/* menu grey #242d3e*/
/* dashboard #181f2a*/

/* corpblue #001864 */
/* corpgold #FFC20E *
/* corplightblue #20C4F4 */

.configBox {
  width: 100%;
  text-align: center !important;
  margin-top: 5vh !important;
}

.tabs {
  color: #001864 !important;
}

.MuiTab-root {
  color: #001864 !important;
}

.tabsdark {
  color: white !important;
}

.buttonback {
  color: #001864 !important;
  margin-left: 5px !important;
  margin-top: 5px !important;
}

.buttonbackdark {
  color: #ffffff !important;
  margin-left: 5px !important;
  margin-top: 5px !important;
}

.entralistitem {
  color: #001864 !important;
  padding: 0rem !important;
  background-color: transparent !important;
}

.entralistitemdark {
  color: #ffffff !important;
  padding: 0rem !important;
  background-color: transparent !important;
}

.configcardtitle {
  color: #001864 !important;
  background-color: transparent !important;
}

.configcardtitledark {
  color: #ffffff !important;
  background-color: transparent !important;
}

.icondrop {
  color: #20c4f4 !important;
  font-size: 12vw !important;
}

.icondropmini {
  color: #20c4f4 !important;
  font-size: 2vw !important;
}

.icondropdark {
  color: #ffffff !important;
  font-size: 12vw !important;
}

.icondropdarkmini {
  color: #ffffff !important;
  font-size: 2vw !important;
}

.dropbox {
  width: 100%;
  height: 100%;
}

.customgroupdropcardsettings,
.customgroupdropcard,
.customgroupdropcardmini {
  position: relative;
  text-align: center !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background: transparent !important;
  box-shadow: none !important;
  /* background: linear-gradient(
    to bottom,
    rgba(31, 195, 244, 0.1),
    rgba(31, 195, 244, 0.1)
  ) !important; */
}

.customgroupdropcard {
  width: 13vw !important;
}

.customgroupdropcardmini {
  width: 3vw !important;
}

.customgroupdropcarddarksettings,
.customgroupdropcarddark,
.customgroupdropcarddarkmini {
  position: relative;
  text-align: center !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background: transparent !important;
  box-shadow: none !important;
  /* background: linear-gradient(
    to bottom,
    rgba(141, 228, 255, 0.1),
    rgba(141, 228, 255, 0.1)
  ) !important; */
}

.customgroupdropcarddark {
  width: 12vw !important;
}

.customgroupdropcarddarkmini {
  width: 2vw !important;
}

.saveiconconfig {
  color: #ffc20e !important;
  font-size: 1.2em !important;
}

.saveiconconfigdark {
  color: #ffffff !important;
  font-size: 1.2em !important;
}

.infoiconconfig {
  color: #20c4f4 !important;
  font-size: 2em !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
}

.infoiconconfigdark {
  color: #20c4f4 !important;
  font-size: 2em !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
}

.refreshiconconfig {
  color: #f700ff !important;
  font-size: 1.5em !important;
}

.refreshiconconfigdark {
  color: #f700ff !important;
  font-size: 1.5em !important;
}

.deleteiconconfig {
  color: #ff0000 !important;
  font-size: 1.5em !important;
}

.deleteiconconfigdark {
  color: #ff0000 !important;
  font-size: 1.5em !important;
}

.cleariconconfig {
  color: #838383 !important;
  font-size: 1.5em !important;
}

.cleariconconfigdark {
  color: #ffffff !important;
  font-size: 1.5em !important;
}

.refreshwhitelistdark {
  color: #ffffff !important;
}

.refreshwhitelist {
  color: #ffc20e !important;
}

.addiconconfig {
  color: #4caf50 !important;
}

.addiconconfigdark {
  color: #ffffff !important;
}

.customsearchlistitem {
  background-color: #ffffff !important;
  color: #242d3e !important;
}

.customsearchlistitem.ifhovered:hover {
  color: #ffc20e !important;
  background-color: #242d3e !important;
}

.customsearchlistitemdark {
  background-color: #242d3e !important;
  color: #ffffff !important;
}

.customsearchlistitemdark.ifhovered:hover {
  color: #242d3e !important;
  background-color: #ffffff !important;
}

.MuiPopover-paper {
  background-color: inherit !important;
}

.helpiconconfig {
  color: #20c4f4 !important;
}

.helpiconconfigdark {
  color: #ffffff !important;
}
