/* menu grey #242d3e*/
/* dashboard #181f2a*/

/* corpblue #001864 */
/* corpgold #FFC20E *
/* corplightblue #20C4F4 */

.viewkeys {
  color: #ffc20e !important;
}

.viewkeysdark {
  color: #ffffff !important;
}

.tablesortlabel {
  font-weight: 800 !important;
  color: #001864 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.5em !important;
  line-height: 4vh !important;
}

.tablesortlabeldark {
  font-weight: 800 !important;
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.5em !important;
  line-height: 4vh !important;
}

.MuiTableSortLabel-icon {
  color: inherit !important;
}

.MuiTablePagination-selectIcon {
  color: inherit !important;
}

.MuiTablePagination-toolbar {
  color: inherit !important;
}

.tablecell {
  font-family: "Montserrat", sans-serif !important;
  color: #001864 !important;
  font-weight: 500 !important;
  font-size: 1.2em !important;
  line-height: 4vh !important;
}

.tablecelldark {
  font-family: "Montserrat", sans-serif !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 1.2em !important;
  line-height: 4vh !important;
}

.generalTable {
  border-bottom: solid transparent !important;
}

.boxtable {
  margin: 1vw;
}

.keyborder {
  color: #001864 !important;
  min-width: fit-content !important;
  max-width: fit-content !important;
  padding: 0 1em 0 1em !important;
  border: 0.2px solid lightgrey !important;
  border-radius: 1.75rem !important;
}

.keyborderdark {
  color: #ffffff !important;
  min-width: fit-content !important;
  max-width: fit-content !important;
  padding: 0 1em 0 1em !important;
  border: 0.2px solid #ffffff !important;
  border-radius: 1.75rem !important;
}

.pagination {
  font-family: "Montserrat", sans-serif !important;
  color: #001864 !important;
}

.paginationdark {
  font-family: "Montserrat", sans-serif !important;
  color: #ffffff !important;
}
